import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';
import Web3, { Contract } from "web3";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ethers } from 'ethers';
import useEthersSigner from './useEthersSigner';


export default function TeamWithdrawal() {
    const { formatAddress, settings, web3, toastSuccess, toastError, formatDateTime } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authNumber, setAuthNumber] = useState('');

    const [account, setAccount] = useState(null);
    const [web3c, setWeb3c] = useState(null);
    const [value, setValue] = useState(0);
    const [trdone, settrdone] = useState(true); // Only one declaration here
    const [selected_address, setselected_address] = useState([]);
    const [selected_address_amt, setselected_address_amt] = useState([]);
    const [selected_address_amt_as, setselected_address_amt_as] = useState([]);
    const [msg, setMsg] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [Data, setEData] = useState([]);

    const signer = useEthersSigner();



    const fetchByIDData = async (address) => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getpbyid",
                key: process.env.REACT_APP_KEY,
                search: search,
                type: 1,
                address: address,
                page: currentPage,
                pageSize: pageSize,
            }).then((res) => {
                setLoading(false);
                if (res.data.error) {
                    setEData(res.data.data);
                    return;
                }
                setEData(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
        } catch (err) {
            setLoading(false);
            console.error("error fetching by ID: ", err);
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getpw",
                key: process.env.REACT_APP_KEY,
                search: search,
                type: 1,

                page: currentPage,
                pageSize: pageSize,
            }).then((res) => {
                setLoading(false);
                if (res.data.error) {
                    setData(res.data.data);
                    return;
                }
                setData(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
        } catch (err) {
            setLoading(false);
            console.error("Error fetching data: ", err);
        }
    };

    const handleDropdownClick = (address) => {
        if (selectedAddress === address) {
            setDropdownOpen(!dropdownOpen);
        } else {
            setSelectedAddress(address);
            fetchByIDData(address);
            setDropdownOpen(true);
        }
    };

    const connectMetaMask = async () => {

        if (window.ethereum) {
            // MetaMask exists
            try {
                // Request account access if needed
                const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
                const web3Instance = new Web3(window.ethereum);

                // Set the web3 instance and the user's account
                setWeb3c(web3Instance);
                setAccount(accounts[0]);

                console.log("Connected account:", accounts[0]);
            } catch (error) {
                console.error("User denied account access or error occurred:", error);
            }
        } else {
            // MetaMask is not installed
            alert("Please install MetaMask to use this app.");
        }
    };


    const selectAll = (event) => {
        if (event?.target?.checked) {
            // Reset arrays and total amount to start fresh
            let allSelectedAddresses = [];
            let allSelectedAmountsWei = [];
            let allSelectedAmounts = [];
            let totalAmount = 0;

            // Loop through all data to check each item and accumulate values
            data.forEach((item, index) => {
                const checkbox = document.getElementById(`chkbox${index}`);
                checkbox.checked = true;  // Check the checkbox

                const address = item.address;
                const amount = parseFloat(item.amount);  // Assuming 'amount' is the right field for your use case
                const token = parseFloat(item.t_rcv);  // Assuming 'amount' is the right field for your use case
                // const token = web3?.utils?.toWei(item.token.toString(), "ether");  // Assuming 'amount' is the right field for your use case
                const amountWei = web3?.utils?.toWei(item.t_rcv.toString(), "ether");
                console.log("amountWei", amountWei);
                allSelectedAddresses.push(address);
                allSelectedAmountsWei.push(amountWei);
                allSelectedAmounts.push(amount);
                totalAmount += token;  // Accumulate total amount
            });
            // Update state with new selection
            setselected_address(allSelectedAddresses);
            setselected_address_amt(allSelectedAmountsWei);
            setselected_address_amt_as(allSelectedAmounts);
            setValue(totalAmount);  // Update the total value


        } else {
            // Uncheck all checkboxes and reset state
            data.forEach((item, index) => {
                const checkbox = document.getElementById(`chkbox${index}`);
                checkbox.checked = false;  // Uncheck the checkbox
            });

            // Clear state and reset total amount
            setselected_address([]);
            setselected_address_amt([]);
            setselected_address_amt_as([]);
            setValue(0);  // Reset total amount
        }
    };


    const { JsonRpcProvider } = ethers.providers; // Import JsonRpcProvider from the providers namespace

    const withdrawMultiple = async () => {
        // console.log("log", selected_address, selected_address_amt);

        if (!signer) {
            console.log("signer is not available");
            return;
        }

        settrdone(false);

        // Define the Arbitrum provider (connecting to the Arbitrum network)
        const arbitrumRpcUrl = "https://bsc-dataseed1.binance.org/"; // Arbitrum mainnet RPC URL
        const provider = new JsonRpcProvider(arbitrumRpcUrl); // Create the provider using the new method

        try {
            // Create contract instance using ethers.js
            const contract = new Contract(settings[0].withdrawal_contract, JSON.parse(settings[0].withdrawal_contract_abi), signer);
            // console.log("sendifn");

            // Estimate gas before sending the transaction
            const tx = await contract.Withdrawal(selected_address, selected_address_amt, value);

            console.log("Transaction sent, waiting for confirmation...");
            console.log("Transaction successful:", tx);
            // console.log("Transaction mined in block:", receipt.blockNumber);

            // Call backend API to register the withdrawal
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "withdrawal",
                    submethod: "newsinsert",
                    key: process.env.REACT_APP_KEY,
                    // hash: res.transactionHash,
                    hash: "hash-1",
                    address: selected_address,
                    amount: selected_address_amt_as,
                    total: value,
                    type: 1,
                })
                .then((response) => {
                    // Uncheck checkboxes after the transaction
                    for (let i = 0; i < selected_address.length; i++) {
                        const checkbox = document.getElementById(`chkbox${i}`);
                        if (checkbox) {
                            checkbox.checked = false;
                        }
                    }

                    getData()
                    setselected_address([])
                    setselected_address_amt([])
                    setValue(0)

                    if (response.data.error) {
                        return "";
                    }

                    toastSuccess("Transaction Done");
                });

        } catch (err) {
            console.error("Error in withdrawMultiple:", err);
            settrdone(true);
        }

        settrdone(true);
    };
    const handleChange = (event, address, amount, token, receive) => {
        console.log('Event triggered with values:', event, address, amount, token, receive);

        if (event?.target?.checked) {
            setselected_address(prevState => {
                const updatedAddress = [...prevState, address];
                console.log("Updated selected_address", updatedAddress);
                return updatedAddress;
            });


            const amt = receive; // Assuming `receive` is already in the correct unit
            console.log("Amount in Wei:", amt);

            setselected_address_amt(prevState => {
                const updatedAmt = [...prevState, amt];
                console.log("Updated selected_address_amt", updatedAmt);
                return updatedAmt;
            });

            setselected_address_amt_as(prevState => {
                const updatedAmtAs = [...prevState, amount];
                console.log("Updated selected_address_amt_as", updatedAmtAs);
                return updatedAmtAs;
            });

            setValue(prevValue => {
                const updatedValue = prevValue + parseFloat(receive);
                console.log("Updated value", updatedValue);
                return updatedValue;
            });
        } else {
            // When the checkbox is unchecked, remove the address, username, and amount from the selected lists
            const index = selected_address.findIndex(item => item === address);

            if (index !== -1) {
                setselected_address(prevState => prevState.filter((_, ind) => ind !== index));
                setselected_address_amt(prevState => prevState.filter((_, ind) => ind !== index));
                setselected_address_amt_as(prevState => prevState.filter((_, ind) => ind !== index));

                setValue(prevValue => prevValue - parseFloat(receive));
            }
        }
    };


    // Now, useEffect can track state changes if you want to log after state has updated
    useEffect(() => {
        console.log('selected_address', selected_address);
        console.log('selected_address_amt', selected_address_amt);
        console.log('selected_address_amt_as', selected_address_amt_as);
    }, [selected_address, selected_address_amt, selected_address_amt_as]);  // This will log whenever these states change



    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        if (fromdate && todate) {
            setCurrentPage(1);
        }
        getData();
    }, [pages, pageSize, currentPage, search]);

    useEffect(() => {
        // console.log(selected_address);
        // console.log(selected_address_amt);
        // console.log(selected_address_amt_as);
    }, [selected_address, selected_address_amt])
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">

                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Team Withdrawal</h3>
                                </div>
                            </div>
                            <div className="d-flex pe-5">
                                <div className="d-flex pe-5">
                                    <div>
                                        <h5 className="py-3 px-5 text-break">🟢{account ? formatAddress(account) : "Not Connected"}</h5>
                                        <h5 className="px-5">Total: {value} {process.env.REACT_APP_TICKER}</h5>
                                        <p className="px-5">
                                            <input className="p-5" type="checkbox" name="chkbox" id="chkbox" onChange={(ed) => selectAll(ed)} /> Select All
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            className="btn btn-success m-1"
                                            onClick={() => withdrawMultiple()}
                                        >
                                            Give Withdrawal
                                        </button>
                                        {account ? (
                                            <>
                                                {trdone ? (
                                                    ""
                                                ) : (
                                                    <img
                                                        src="/assets/images/loader.gif"
                                                        style={{ height: "80px" }}
                                                        alt="Loading"
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <ConnectButton />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    {account ? (
                                        trdone ? (
                                            <button className="btn btn-success m-1" onClick={withdrawMultiple}>Give Withdrawal</button>
                                        ) : (
                                            <img src="/assets/images/loader.gif" style={{ height: "80px" }} alt="icon" />
                                        )
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <hr />

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-header"></div>
                                            <div className="table-responsive signal-table p-3">
                                                <div className="col-sm-4">
                                                    <div className="position-relative">
                                                        <input type="text" value={search} onChange={handleSearchChange} className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                                                        <span className="mdi mdi-magnify search-widget-icon" />
                                                        <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                                                    </div>
                                                </div>
                                                <table className="table table-hover">

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Address</th>
                                                            <th>Team Withdrawal</th>
                                                            <th>Amount</th>
                                                            <th>Liquidity</th>
                                                            <th>Fee(5%)</th>
                                                            <th>Receive</th>
                                                            <th>Bot</th>
                                                            <th>DateTime</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {search && data && data.length ? (
                                                            data.map((e, i) => {
                                                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{ind} <input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.amount, e.token, e.t_rcv)} /></td>
                                                                        <td>
                                                                            <a target="_blank" className="text-primary" href={`${process.env.REACT_APP_EXPLORER}/tx/${e.address}`} rel="noreferrer">
                                                                                {formatAddress(e.address)}
                                                                            </a>
                                                                            {e.address && <Copy data={e.address} />}
                                                                        </td>
                                                                        <td>
                                                                            <td>
                                                                                <button
                                                                                    onClick={() => handleDropdownClick(e.address)}
                                                                                    className={`btn btn-primary d-flex align-items-center gap-2`}
                                                                                >
                                                                                    {dropdownOpen && selectedAddress === e.address ? (
                                                                                        <>
                                                                                            <span>Close</span>
                                                                                            <i className="mdi mdi-chevron-up" />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <span>Open</span>
                                                                                            <i className="mdi mdi-chevron-down" />
                                                                                        </>
                                                                                    )}
                                                                                </button>

                                                                                {selectedAddress === e.address && dropdownOpen && (
                                                                                    <div className="dropdown-content card shadow-lg mt-2">
                                                                                        <div className="card-body p-3">
                                                                                            <h6 className="mb-3">Details for {formatAddress(e.address)}</h6>
                                                                                            <table className="table table-bordered table-sm">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>#</th>
                                                                                                        <th>Address</th>
                                                                                                        <th>Amount</th>
                                                                                                        <th>Liquidity</th>
                                                                                                        <th>Fee(5%)</th>
                                                                                                        <th>Receive</th>
                                                                                                        <th>Bot</th>
                                                                                                        <th>DateTime</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {Data.map((e, i) => {
                                                                                                        const index = currentPage * pageSize - pageSize + (i + 1);
                                                                                                        return (
                                                                                                            <tr key={i}>
                                                                                                                <td>{index} <input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.amount, e.token, e.t_rcv)} /></td>
                                                                                                                <td>
                                                                                                                    <a
                                                                                                                        target="_blank"
                                                                                                                        className="text-primary"
                                                                                                                        href={`${process.env.REACT_APP_EXPLORER}/tx/${e.address}`}
                                                                                                                        rel="noreferrer"
                                                                                                                    >
                                                                                                                        {formatAddress(e.address)}
                                                                                                                    </a>
                                                                                                                </td>
                                                                                                                <td>${(e.amount || 0).toFixed(2)}</td>
                                                                                                                <td>${(e.t_staking_s || 0).toFixed(2)}</td>
                                                                                                                <td>{(e.fee || 0).toFixed(2)}</td>
                                                                                                                <td>${(e.t_rcv || 0).toFixed(2)}</td>
                                                                                                                <td>
                                                                                                                    {e.amount === 25
                                                                                                                        ? "BEGINNER BOT"
                                                                                                                        : e.amount === 100
                                                                                                                            ? "INTERMEDIATE BOT"
                                                                                                                            : e.amount === 500
                                                                                                                                ? "PROFESSIONAL BOT"
                                                                                                                                : "Invalid Bot"}
                                                                                                                </td>
                                                                                                                <td>{formatDateTime(e.createdAt)}</td>
                                                                                                            </tr>
                                                                                                        );
                                                                                                    })}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </td>

                                                                        </td>
                                                                        <td>
                                                                            <span>${(e.amount || 0).toFixed(2)}</span>
                                                                        </td>
                                                                        <td><span>${(e.t_staking_s || 0).toFixed(2)}</span></td>
                                                                        <td><span>{(e.fee || 0).toFixed(2)}</span></td>
                                                                        <td><span>${(e.t_rcv || 0).toFixed(2)}</span></td>
                                                                        <td>
                                                                            <span>
                                                                                {e.amount === 25 ? "BEGINNER BOT" : e.amount === 100 ? "INTERMEDIATE BOT" : e.amount === 500 ? "PROFESSIONAL BOT" : "Invalid Bot"}
                                                                            </span>
                                                                        </td>
                                                                        <td>{e.datetime}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="9" className="text-center">
                                                                    {search ? "No data found for this search." : "Please enter a search Address."}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                                </table>
                                                {loading && <div className="text-center"><p>Loading...</p></div>}
                                            </div>
                                            <div className="d-flex justify-content-center py-4">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    pageSize={pageSize}
                                                    setPageSize={setPageSize}
                                                    pages={pages}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        </>
    )
}