import { ethers } from 'ethers'; // <-- Import ethers
import { defineChain } from 'viem';

const BSCMAINNET = defineChain({
  id: 56,
  name: 'BSC Mainnet',
  nativeCurrency: { name: 'BSC Mainnet', symbol: 'BNB', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://bsc-dataseed.binance.org'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Bscscan',
      url: 'https://bscscan.com',
    },
  },
})

export const chains = [BSCMAINNET]