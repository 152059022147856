import { useWalletClient } from 'wagmi';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';

export default function useEthersSigner() {
  const { data } = useWalletClient(); // Access wallet data from wagmi
  const [signer, setSigner] = useState(null); // State for signer

  useEffect(() => {
    if (data) {
      // Create Web3Provider and get signer
      const provider = new ethers.providers.Web3Provider(data);
      const signerInstance = provider.getSigner();
      setSigner(signerInstance);
    } else {
      setSigner(null); // Reset signer if no wallet data
    }
  }, [data]);

  return signer;
}
